/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Col, Row, Space, Select, Spin } from 'antd';
import { formattedAmount, getMonthNames, monthNames } from 'helpers/utils';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { get } from 'lodash';
import invoiceApi from 'api/invoiceApi';
import { useAuth } from 'hooks/useAuth';
import IconButton from 'components/common/IconButton';
import Loading from 'components/attendance/Loading';
import { getPreviousMonthNames } from 'helpers/utils';
import { Card } from 'react-bootstrap';
import ProfitLossTable from 'components/employeeSalary/profitLossTable';

const ProfitLossReport = () => {
  const { user } = useAuth();
  const [data, setData] = useState([]);
  const todayDate = new Date();
  const monthNameList = getMonthNames(todayDate.getFullYear());
  const [monthOptions, setMonthOptions] = useState(monthNameList);
  const [currentMonth, setCurrentMonth] = useState(todayDate.getMonth());

  const [currentYear, setCurrentYear] = useState(todayDate.getFullYear());
  const [modalVisible, setModalVisible] = useState(true);

  const yearOptions = [
    {
      value: 2023,
      label: '2023'
    },
    {
      value: 2024,
      label: '2024'
    },
    {
      value: 2025,
      label: '2025'
    },
    {
      value: 2026,
      label: '2026'
    }
  ];

  useEffect(() => {
    const token = get(user, 'token');
    const month = currentMonth;
    const year = currentYear;

    setModalVisible(true);
    getSiteSalaryDetails(month, year, token);
  }, [currentMonth, currentYear]);

  useEffect(() => {
    const monthNameList = getPreviousMonthNames(currentYear);
    setMonthOptions(monthNameList);
  }, [currentYear]);

  const totalColumn = [
    {
      title: 'BILLING AMOUNT ',
      dataIndex: 'billingAmount',
      key: 'billingAmount',
      width: 60
    },
    {
      title: 'SALARY PAID',
      dataIndex: 'grossSalaryPaid',
      key: 'grossSalaryPaid',
      width: 50
    },
    {
      title: 'PENDING SALARY',
      dataIndex: 'pendingSalary',
      key: 'pendingSalary',
      width: 55
    },
    {
      title: 'EXPENSES',
      dataIndex: 'siteExpenses',
      key: 'siteExpenses',
      width: 55
    },
    {
      title: 'GROSS PROFIT',
      dataIndex: 'grossProfit',
      key: 'grossProfit',
      width: 60
    },
    {
      title: 'OVER HEAD EXPENSES',
      dataIndex: 'overHeadExpenses',
      key: 'overHeadExpenses',
      width: 60
    },
    {
      title: 'NET PROFIT',
      dataIndex: 'netProfit',
      key: 'netProfit',
      width: 60
    }
  ];

  const defaultColumn = [
    {
      title: 'S.NO',
      dataIndex: 'sno',
      fixed: 'left',
      key: '',
      width: 20,
      render: (text, record, index) => index + 1
    },
    {
      title: 'UNIT CODE',
      dataIndex: 'siteId',
      key: 'siteId',
      editable: true,
      fixed: 'left',
      width: 40
    },
    {
      title: 'UNIT NAME',
      dataIndex: 'siteName',
      key: 'siteName',
      editable: true,
      fixed: 'left',
      width: 60
    }
  ];
  const columns = [...defaultColumn, ...totalColumn];

  const onClickExportAsExcel = () => {
    const currentMonthName = monthNames[currentMonth].label;
    const dataToExport = data.map(record =>
      columns.map(column => get(record, column.dataIndex, ''))
    );

    const worksheet = XLSX.utils.aoa_to_sheet([
      columns.map(column => column.title),
      ...dataToExport
    ]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, currentMonthName);

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    saveAs(blob, 'Salary.xlsx');
  };

  const calculateTotalAmount = (data, field) => {
    return data.reduce(
      (accumulator, item) => accumulator + (Number(item[field]) || 0),
      0
    );
  };

  const getSiteSalaryDetails = (month, year, token) => {
    invoiceApi
      .getProfitLossReport(month, year, token)
      .then(response => {
        const responseData = get(response, 'data.data', []);
        console.log(responseData);

        const finalTotal = [...responseData];
        finalTotal.push({
          siteId: 'TOTAL',
          // siteName: 'TOTAL',
          siteExpenses: formattedAmount(
            calculateTotalAmount(responseData, 'siteExpenses')
          ),
          grossSalaryPaid: formattedAmount(
            calculateTotalAmount(responseData, 'grossSalaryPaid')
          ),
          pendingSalary: formattedAmount(
            calculateTotalAmount(responseData, 'pendingSalary')
          ),
          billingAmount: formattedAmount(
            calculateTotalAmount(responseData, 'billingAmount')
          ),
          grossProfit: formattedAmount(
            calculateTotalAmount(responseData, 'grossProfit')
          ),
          overHeadExpenses: formattedAmount(
            calculateTotalAmount(responseData, 'overHeadExpenses')
          ),
          netProfit: formattedAmount(
            calculateTotalAmount(responseData, 'netProfit')
          )
        });

        setData(finalTotal);
        setModalVisible(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setModalVisible(false);
      });
  };

  const handleChangeYear = value => {
    setCurrentYear(value);
    console.log(value);
  };

  const handleChangeMonth = value => {
    const currentMonthName = monthNames[value].value;
    setCurrentMonth(currentMonthName);
  };

  const style = {
    padding: '8px 0'
  };

  if (modalVisible) {
    return (
      <div className="text-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Card>
        <Card.Header className="bg-shape modal-shape-header px-20 position-relative">
          <div className="position-relative light">
            <h5 className="mb-0 text-white">Profit And Loss Report</h5>
          </div>
        </Card.Header>
        <br />
        <Loading visible={modalVisible} />
        <Row align="middle">
          <Col style={style} span={13} offset={1}>
            <Space wrap>
              {/* <Select
                showSearch
                defaultValue={currentSiteId}
                placeholder="Select a site"
                optionFilterProp="children"
                onChange={handleChangeSiteId}
                onSearch={onSearch}
                filterOption={filterOption}
                options={siteIdsOptions}
                style={{
                  width: 410
                }}
              /> */}

              <Select
                defaultValue={currentYear}
                style={{
                  width: 120
                }}
                onChange={handleChangeYear}
                options={yearOptions}
              />
              <Select
                defaultValue={currentMonth}
                value={currentMonth}
                style={{
                  width: 120
                }}
                onChange={handleChangeMonth}
                options={monthOptions}
              />
              <div id="orders-actions">
                <IconButton
                  variant="primary"
                  size="sm"
                  icon="external-link-alt"
                  transform="shrink-3"
                  onClick={onClickExportAsExcel}
                >
                  <span className="d-none d-sm-inline-block ms-1">Export</span>
                </IconButton>
              </div>
            </Space>
          </Col>
        </Row>
        <Row>
          <ProfitLossTable columns={columns} tableData={data} />
        </Row>
      </Card>
    </>
  );
};
export default ProfitLossReport;
