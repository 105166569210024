/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Col, Row, Space, Select, Spin } from 'antd';
import { formattedAmount, getMonthNames, monthNames } from 'helpers/utils';
import SimpleBarReact from 'simplebar-react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { get, upperCase } from 'lodash';
import invoiceApi from 'api/invoiceApi';
import { useAuth } from 'hooks/useAuth';
import IconButton from 'components/common/IconButton';
import Loading from 'components/attendance/Loading';
import { getPreviousMonthNames } from 'helpers/utils';
import { Card, Table } from 'react-bootstrap';
// import ProfitLossTable from 'components/employeeSalary/profitLossTable';

const ViprasMartProfitAndLossReport = () => {
  const { user } = useAuth();
  const [data, setData] = useState([]);
  const todayDate = new Date();
  const monthNameList = getMonthNames(todayDate.getFullYear());
  const [monthOptions, setMonthOptions] = useState(monthNameList);
  const [currentMonth, setCurrentMonth] = useState(todayDate.getMonth());
  const [totalIncome, setTotalIncome] = useState();
  const [totalExpenses, setTotalExpenses] = useState();
  const [currentYear, setCurrentYear] = useState(todayDate.getFullYear());
  const [modalVisible, setModalVisible] = useState(true);

  const yearOptions = [
    {
      value: 2023,
      label: '2023'
    },
    {
      value: 2024,
      label: '2024'
    },
    {
      value: 2025,
      label: '2025'
    },
    {
      value: 2026,
      label: '2026'
    }
  ];

  useEffect(() => {
    const token = get(user, 'token');
    const month = currentMonth;
    const year = currentYear;

    setModalVisible(true);
    getSiteSalaryDetails(month, year, token);
  }, [currentMonth, currentYear]);

  useEffect(() => {
    const monthNameList = getPreviousMonthNames(currentYear);
    setMonthOptions(monthNameList);
  }, [currentYear]);

  const defaultColumn = [
    {
      title: 'S.NO',
      dataIndex: 'sno',
      fixed: 'left',
      key: '',
      width: 20,
      render: (text, record, index) => index + 1
    },
    {
      title: 'GROSS BILLING',
      dataIndex: 'billingAmount',
      key: 'billingAmount',
      editable: true,
      fixed: 'left',
      width: 60,
      render: text => Math.round(text)
    },
    {
      title: 'SALARY',
      dataIndex: 'grossSalary',
      key: 'grossSalary',
      editable: true,
      fixed: 'left',
      width: 60,
      render: text => Math.round(text)
    },
    {
      title: 'EXPENSES',
      dataIndex: 'expenses',
      key: 'expenses',
      editable: true,
      fixed: 'left',
      width: 60,
      render: text => Math.round(text)
    },
    {
      title: 'BONUS',
      dataIndex: 'bonus',
      key: 'bonus',
      editable: true,
      fixed: 'left',
      width: 60,
      render: text => Math.round(text)
    },
    {
      title: 'OHEXPENSES',
      dataIndex: 'ohExpenses',
      key: 'ohExpenses',
      editable: true,
      fixed: 'left',
      width: 60,
      render: text => Math.round(text)
    }
  ];
  const columns = [...defaultColumn];

  const onClickExportAsExcel = () => {
    const currentMonthName = monthNames[currentMonth].label;
    const dataToExport = data.map(record =>
      columns.map(column => get(record, column.dataIndex, ''))
    );

    const worksheet = XLSX.utils.aoa_to_sheet([
      columns.map(column => column.title),
      ...dataToExport
    ]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, currentMonthName);

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    saveAs(blob, 'Vipras-Mart-Profit-Loss-Report.xlsx');
  };

  const getSiteSalaryDetails = (month, year, token) => {
    const siteId = 'UC056';
    invoiceApi
      .getViprasMartProfitAndLoss(siteId, month + 1, year, token)
      .then(response => {
        const responseData = get(response, 'data.data.items', []);
        setData(responseData);
        const {
          expenses = 0,
          bonus = 0,
          grossSalary = 0,
          ohExpenses = 0,
          billingAmount = 0
        } = responseData[0] || {};
        const totalExpenses = expenses + bonus + grossSalary + ohExpenses;
        setTotalExpenses(totalExpenses);
        setTotalIncome(billingAmount);
        setModalVisible(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setModalVisible(false);
      });
  };

  const handleChangeYear = value => {
    setCurrentYear(value);
    console.log(value);
  };

  const handleChangeMonth = value => {
    const currentMonthName = monthNames[value].value;
    setCurrentMonth(currentMonthName);
  };

  const style = {
    padding: '8px 0'
  };

  if (modalVisible) {
    return (
      <div className="text-center">
        <Spin size="large" />
      </div>
    );
  }

  const labels = [
    'GROSS BILLING',
    'SALARY',
    'EXPENSES',
    'BONUS',
    'OHEXPENSES',
    'NET RESULT'
  ];

  return (
    <>
      <Card>
        <Card.Header className="bg-shape modal-shape-header px-20 position-relative">
          <div className="position-relative light">
            <h5 className="mb-0 text-white">
              {' '}
              Vipras Mart Profit And Loss Report
            </h5>
          </div>
        </Card.Header>
        <br />
        <Loading visible={modalVisible} />
        <Row align="middle">
          <Col style={style} span={13} offset={1}>
            <Space wrap>
              <Select
                defaultValue={currentYear}
                style={{
                  width: 120
                }}
                onChange={handleChangeYear}
                options={yearOptions}
              />
              <Select
                defaultValue={currentMonth}
                value={currentMonth}
                style={{
                  width: 120
                }}
                onChange={handleChangeMonth}
                options={monthOptions}
              />
              <div id="orders-actions">
                <IconButton
                  variant="primary"
                  size="sm"
                  icon="external-link-alt"
                  transform="shrink-3"
                  onClick={onClickExportAsExcel}
                >
                  <span className="d-none d-sm-inline-block ms-1">Export</span>
                </IconButton>
              </div>
            </Space>
          </Col>
        </Row>
        <Card className="mb-3" id="pdf-content">
          <Card.Body>
            <div className="fs--1">
              <SimpleBarReact>
                <Table className="border-bottom">
                  <thead className="light">
                    <tr className="bg-primary text-white dark__bg-1000 invoice-th">
                      <th className="border-0 ">Particular</th>
                      <th className="border-0 text-center">Income</th>
                      <th className="border-0 text-center">Expenses</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data.map((item, index) => {
                      return labels.map((label, labelIndex) => {
                        let value = '';
                        switch (label) {
                          case 'GROSS BILLING':
                            value = item.billingAmount;
                            break;
                          case 'SALARY':
                            value = item.grossSalary;
                            break;
                          case 'EXPENSES':
                            value = item.siteExpenses;
                            break;
                          case 'BONUS':
                            value = item.bonus;
                            break;
                          case 'OHEXPENSES':
                            value = item.ohExpenses;
                            break;

                          case 'NET RESULT': {
                            const netResult =
                              item.billingAmount -
                              item.grossSalary -
                              item.siteExpenses -
                              item.bonus -
                              item.ohExpenses;
                            if (netResult >= 0) {
                              value = `Gross Profit: ${formattedAmount(
                                netResult
                              )}`;
                            } else {
                              value = `Gross Loss: ${formattedAmount(
                                netResult
                              )}`;
                            }
                            break;
                          }
                          default:
                            break;
                        }

                        return (
                          <tr key={`${label}-${index}`}>
                            <td className="align-middle">
                              <h6 className="mb-0 text-nowrap text-color-black">
                                {label}
                              </h6>
                            </td>
                            <td className="align-middle text-center">
                              {label === 'GROSS BILLING' ? value : ''}
                            </td>
                            <td className="align-middle text-center ">
                              {label !== 'GROSS BILLING' ? value : ''}
                            </td>
                          </tr>
                        );
                      });
                    })}
                  </tbody>
                </Table>
              </SimpleBarReact>
            </div>
          </Card.Body>
        </Card>
      </Card>
    </>
  );
};
export default ViprasMartProfitAndLossReport;
