import axios from 'axios';
import { settings } from 'config';
import { monthNames } from 'helpers/utils';
import { get, trim } from 'lodash';

const getInvoicedetails = token => {
  return axios.get(`${settings.apiUrl}/api/v1/invoice/UC110/January-2024`, {
    headers: {
      'erp-token': token
    }
  });
};
const addInvoice = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/invoice`, data, {
    headers: {
      'erp-token': token
    }
  });

const updateInvoice = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/invoice/${data.id}`, data, {
    headers: {
      'erp-token': token
    }
  });

const getByIdInvoicedetails = (id, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/invoice/${id}`, {
    headers: {
      'erp-token': token
    }
  });
};

const getInvoice = token => {
  return axios.get(`${settings.apiUrl}/api/v1/invoice`, {
    headers: {
      'erp-token': token
    }
  });
};

const InvoiceReportBySiteId = (month, token) => {
  const filter = {
    month: month,
    isGstInvoice: true
  };
  const serializedData = JSON.stringify(filter);
  return axios.get(
    `${settings.apiUrl}/api/v1/invoice?filter=${serializedData}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const SubContracterInvoiceReportBySiteId = (month, token) => {
  const filter = {
    month: month,
    isGstInvoice: false
  };
  const serializedData = JSON.stringify(filter);
  return axios.get(
    `${settings.apiUrl}/api/v1/invoice?filter=${serializedData}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const fetchInvoicedetails = async (page, pageSize, pageFilter, token) => {
  const offset = page + 1;
  let url = `${settings.apiUrl}/api/v1/invoice?page=${offset}&limit=${pageSize}`;

  if (trim(pageFilter.key).length > 1) {
    const filter = { [`${pageFilter.key}`]: pageFilter.value };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/invoice?filter=${serializedData}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

const getInvoiceMonthly = (siteId, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/invoice/create/${siteId}/${year}/${month}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const getProfitLossReport = (month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/impresttransaction/profit/loss/report/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const getGrossProfitAndLoss = (month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/invoice/profit/loss/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const getViprasMartProfitAndLoss = (siteId, month, year, token) => {
  const monthNameObj = monthNames.find(m => m.value === month - 1);
  const filter = {
    month: `${monthNameObj?.label}-${year}`,
    siteId: siteId
  };
  const serializedData = JSON.stringify(filter);

  return axios.get(
    `${settings.apiUrl}/api/v1/profit/lose?filter=${serializedData}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const getServicesProfitAndLoss = (month, year, departmentServices, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/profit/lose/department/${month}/${year}/${departmentServices}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const getOverheadCalculation = (month, year, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/profit/lose/${month}/${year}`, {
    headers: {
      'erp-token': token
    }
  });
};

const getGarbageDisposal = (month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/profit/lose/garbage/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

// const getHkConsumable = (month, year, token) => {
//   return axios.get(
//     `${settings.apiUrl}/api/v1/profit/lose/generateProfitLoseReport/${month}/${year}`,
//     {
//       headers: {
//         'erp-token': token
//       }
//     }
//   );
// };

const getHkConsumable = async (currentMonth, currentYear, token) => {
  console.log(currentMonth);
  console.log(currentYear);
  let url = `${settings.apiUrl}/api/v1/profit/lose/generateProfitLoseReport/${currentMonth}/${currentYear}`;
  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data', []) || [];
    const hasError = get(response, 'data.success');
    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

const fetchInvoiceOutStanding = (unitCode, month, year, token) => {
  const defaultFilter = {
    siteId: unitCode,
    month: `${month}-${year}`
  };
  const serializedData = JSON.stringify(defaultFilter);
  return axios.get(
    `${settings.apiUrl}/api/v1/invoice/outstanding?filter=${serializedData}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

export default {
  getInvoicedetails,
  fetchInvoicedetails,
  getInvoice,
  getByIdInvoicedetails,
  InvoiceReportBySiteId,
  addInvoice,
  getInvoiceMonthly,
  SubContracterInvoiceReportBySiteId,
  getProfitLossReport,
  updateInvoice,
  getGrossProfitAndLoss,
  fetchInvoiceOutStanding,
  getViprasMartProfitAndLoss,
  getHkConsumable,
  getOverheadCalculation,
  getGarbageDisposal,
  getServicesProfitAndLoss
};
