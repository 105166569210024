/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Col, Row, Space, Select, Spin } from 'antd';
import { formattedAmount, getMonthNames, monthNames } from 'helpers/utils';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { get } from 'lodash';
import invoiceApi from 'api/invoiceApi';
import { useAuth } from 'hooks/useAuth';
import IconButton from 'components/common/IconButton';
import Loading from 'components/attendance/Loading';
import { getPreviousMonthNames } from 'helpers/utils';
import { Card } from 'react-bootstrap';
import GstInvoiceTable from './gstInvoiceTable';

const GstInvoiceReport = () => {
  const { user } = useAuth();
  const [data, setData] = useState([]);
  const todayDate = new Date();
  const monthNameList = getMonthNames(todayDate.getFullYear());
  const [monthOptions, setMonthOptions] = useState(monthNameList);
  const [currentMonth, setCurrentMonth] = useState(todayDate.getMonth());
  const [currentYear, setCurrentYear] = useState(todayDate.getFullYear());
  const [modalVisible, setModalVisible] = useState(true);

  const yearOptions = [
    {
      value: 2023,
      label: '2023'
    },
    {
      value: 2024,
      label: '2024'
    },
    {
      value: 2025,
      label: '2025'
    },
    {
      value: 2026,
      label: '2026'
    }
  ];

  useEffect(() => {
    const token = get(user, 'token');
    const month = currentMonth + 1;
    const year = currentYear;
    const currentMonthNames = monthList[currentMonth];
    const currentMonthLabel = `${currentMonthNames}-${currentYear}`;
    setModalVisible(true);
    getSiteSalaryDetails(currentMonthLabel, token);
  }, [currentMonth, currentYear]);
  const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  useEffect(() => {
    const monthNameList = getPreviousMonthNames(currentYear);
    setMonthOptions(monthNameList);
  }, [currentYear]);

  const totalColumn = [
    {
      title: 'INVOICE NO',
      dataIndex: 'gstInvoiceNo',
      key: 'gstInvoiceNo',
      // editable: true,
      // fixed: 'left',
      width: 50
      // render: (text, record) => {
      //   if (record.isGstInvoice === true) {
      //     return record.gstInvoiceNo;
      //   } else {
      //     return record.invoiceNo;
      //   }
      // }
    },
    {
      title: 'GST NUMBER',
      dataIndex: 'gstNumber',
      // editable: true,
      // fixed: 'left',
      width: 55
    },
    {
      title: 'PO DATE',
      dataIndex: 'poDate',
      width: 55,
      render: (text, record) => {
        if (record.poDate) {
          const date = new Date(record.poDate);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear().toString();
          return <span>{`${day}/${month}/${year}`}</span>;
        }
        return '';
      }
    },
    {
      title: 'PO NUMBER',
      dataIndex: 'poNumber',
      width: 55
    },
    {
      title: 'TAXABLE AMOUNT',
      dataIndex: 'invoieBreakUP',
      key: 'invoieBreakUP.totalAmount',
      width: 80,
      render: invoieBreakUP => {
        if (invoieBreakUP) {
          const total = invoieBreakUP.reduce(
            (sum, item) => sum + (item.totalAmount || 0),
            0
          );
          return formattedAmount(total);
        }
        return 0;
      }
    },
    {
      title: 'TAX AMOUNT',
      dataIndex: 'totalTaxAmount',
      key: 'totalTaxAmount',
      width: 80
    },
    {
      title: 'GRAND TOTAL AMOUNT',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      width: 80
    },
    {
      title: 'MONTH',
      dataIndex: 'month',
      key: 'month',
      width: 60
    }
  ];

  const defaultColumn = [
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 'date',
      editable: true,
      fixed: 'left',
      width: 50,
      render: (text, record) => {
        if (record.date === 'TOTAL') {
          return <span>TOTAL</span>;
        }
        const date = new Date(record.date);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear().toString();
        return <span>{`${day}/${month}/${year}`}</span>;
      }
    },
    {
      title: 'SITE NAME',
      dataIndex: 'siteName',
      key: 'siteName',
      editable: true,
      fixed: 'left',
      width: 60
      // render: (text, record) =>
      //   record.siteName ? record.siteName : record.clientName
    },
    {
      title: 'SITE ID',
      dataIndex: 'siteId',
      key: 'siteId',
      editable: true,
      fixed: 'left',
      width: 60
      // render: (text, record) =>
      //   record.siteId ? record.siteId : record.clientAddress
    }
  ];
  const columns = [...defaultColumn, ...totalColumn];

  const onClickExportAsExcel = () => {
    const currentMonthName = monthNames[currentMonth].label;
    const dataToExport = data.map(record =>
      columns.map(column => get(record, column.dataIndex, ''))
    );

    const worksheet = XLSX.utils.aoa_to_sheet([
      columns.map(column => column.title),
      ...dataToExport
    ]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, currentMonthName);

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    saveAs(blob, 'Invoice.xlsx');
  };

  const getSiteSalaryDetails = (currentMonthLabel, token) => {
    invoiceApi
      .InvoiceReportBySiteId(currentMonthLabel, token)
      .then(response => {
        const responseData = get(response, 'data.data.items', []);

        const totalAmount = responseData.reduce(
          (accumulator, item) => accumulator + (item.totalAmount || 0),
          0
        );
        // const totalTaxableAmount = responseData.reduce((accumulator, item) => {
        //   if (item.invoieBreakUP) {
        //     return (
        //       accumulator +
        //       item.invoieBreakUP.reduce(
        //         (sum, entry) => sum + (entry.totalAmount || 0),
        //         0
        //       )
        //     );
        //   }
        //   return accumulator;
        // }, 0);
        const totalTaxAmount = responseData.reduce(
          (accumulator, item) => accumulator + (item.totalTaxAmount || 0),
          0
        );
        const defaultDate = 'TOTAL';
        const finalTotal = [...responseData];
        finalTotal.push({
          date: defaultDate,
          totalTaxAmount: totalTaxAmount,
          totalAmount: totalAmount
        });
        setData(finalTotal);
        setModalVisible(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setModalVisible(false);
      });
  };

  const handleChangeYear = value => {
    setCurrentYear(value);
    console.log(value);
  };

  const handleChangeMonth = value => {
    const currentMonthName = monthNames[value].value;
    setCurrentMonth(currentMonthName);
  };

  const style = {
    padding: '8px 0'
  };

  if (modalVisible) {
    return (
      <div className="text-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Card>
        <Card.Header className="bg-shape modal-shape-header px-20 position-relative">
          <div className="position-relative light">
            <h5 className="mb-0 text-white">Invoice Reports</h5>
          </div>
        </Card.Header>
        <br />
        <Loading visible={modalVisible} />
        <Row align="end me-3">
          <Col style={style} offset={1}>
            <Space wrap>
              <Select
                defaultValue={currentYear}
                style={{
                  width: 120
                }}
                onChange={handleChangeYear}
                options={yearOptions}
              />
              <Select
                defaultValue={currentMonth}
                value={currentMonth}
                style={{
                  width: 120
                }}
                onChange={handleChangeMonth}
                options={monthOptions}
              />
              <div id="orders-actions">
                <IconButton
                  variant="primary"
                  size="sm"
                  icon="external-link-alt"
                  transform="shrink-3"
                  onClick={onClickExportAsExcel}
                >
                  <span className="d-none d-sm-inline-block ms-1">Export</span>
                </IconButton>
              </div>
            </Space>
          </Col>
        </Row>
        <Row>
          <GstInvoiceTable
            columns={columns}
            tableData={data}
            currentMonth={currentMonth + 1}
            currentYear={currentYear}
          />
        </Row>
      </Card>
    </>
  );
};
export default GstInvoiceReport;
